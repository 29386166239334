import useCountDown from "../useCountDown"
import useTimerSince from "../useTimerSince"

const useTimer = (datetime, { isDisabled }) => {
  const since_seconds = useTimerSince(datetime, { isDisabled })
  const countdown_seconds = useCountDown(datetime, { isDisabled })
  if (since_seconds === null && countdown_seconds === null) return null
  return Math.max(since_seconds, countdown_seconds)
}

export default useTimer