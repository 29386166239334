import { useEffect, useState } from 'react'
import moment from 'moment'
const calculateDifference = (s_time, e_time) => Math.max(moment.utc(e_time).diff(moment.utc(s_time), 'second'), 0)

const useIntervalTiming = (start_time, end_time, { isDisabled } = {}) => {
  const [calculatedTime, setCalculatedTime] = useState(isDisabled ? null : calculateDifference(start_time, end_time))
  useEffect(() => {
    if (!isDisabled) {
      setCalculatedTime(() => calculateDifference(start_time, end_time))
      const intervalId = setInterval(() => setCalculatedTime(() => calculateDifference(start_time, end_time)), 1000)
      return () => clearInterval(intervalId)
    }
    else setCalculatedTime(() => null)
  }, [start_time, end_time, isDisabled])
  return calculatedTime
}

export default useIntervalTiming