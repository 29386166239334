import _ from 'lodash'
import { useCallback } from 'react'
import useProviderStatusListener from '../../root/useProviderStatusListener'
import { useSyncExternalStoreWithSelector } from 'use-sync-external-store/with-selector'
import { ProviderInstance } from '../../../provider'
import isEqual from 'react-fast-compare'

const useActiveUserInfo = () => {
  const listener = useProviderStatusListener('auth')
  return useSyncExternalStoreWithSelector(
    listener,
    useCallback(() => ProviderInstance.getProvider('auth').getUserInfo(), []),
    null,
    _.identity,
    isEqual
  )
}

export default useActiveUserInfo