import { useCountDown } from "../../hooks"

const CountDownComponent = ({ endTime, mutator = _.identity, isDisabled, hideIfElapsed }) => {
  const seconds = useCountDown(endTime, { isDisabled })
  if (!endTime || (hideIfElapsed && seconds <= 0)) return null
  return _.isFinite(seconds) ? mutator(seconds) : null
}


export default CountDownComponent

export {
  CountDownComponent
}