import _ from 'lodash'
import useModel from '../../store/useModel'
import useActiveUserInfo from './useActiveUserInfo'

const useActiveUser = (options) => {
  const user_info = useActiveUserInfo()
  return useModel('user', [user_info?.user_id], _.merge({}, options, { single: true }))
}

export default useActiveUser
