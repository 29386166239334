import { useEffect, useCallback, useMemo } from 'react'
import { useFormState, useFormStatus, useFieldMeta } from '../../../../hooks'
import Callbacks from './callbacks'
import _ from 'lodash'

const useFieldReporter = (field, value, { isFieldReadOnly, isSectionReadOnly, isFieldDisabled, validateField, validateOnBlur, setNormalizedFieldValue, defaultValue, alwaysInclude, autoFocus }) => {
  const [meta, updateFieldMeta, removeFieldMeta] = useFieldMeta(field)
  const isFormMounted = useFormStatus('isMounted')
  const [isReadOnly] = useFormState('isReadOnly')
  const [isEditable] = useFormState('isEditable')
  const [isExperimentalUnmountField] = useFormState('isExperimentalUnmountField')

  const onError = useCallback((error) => updateFieldMeta({ internal_error: error || null }), [updateFieldMeta])
  const onFocus = useCallback(() => updateFieldMeta({ visited: true, active: true, focus: false }), [updateFieldMeta])
  const onValidateField = useCallback(Callbacks.onFieldValidationHandler(validateField, updateFieldMeta), [validateField, updateFieldMeta])
  const onBlur = useCallback(
    Callbacks.onBlurHandler(setNormalizedFieldValue, onValidateField, updateFieldMeta, { validateOnBlur }),
    [setNormalizedFieldValue, onValidateField, updateFieldMeta, validateOnBlur]
  )
  const onFieldBlur = useCallback(() => onBlur(value, defaultValue), [onBlur, value, defaultValue])
  const canUpdateMeta = useMemo(() => !!isFormMounted && !_.isEmpty(meta), [isFormMounted, meta])

  useEffect(() => {
    if (!!isFormMounted)
      return () => !_.isEmpty(meta) && !isReadOnly && (!isFormMounted || !!isExperimentalUnmountField) && removeFieldMeta()
    return _.noop
  }, [isReadOnly, _.isEmpty(meta), isFormMounted])

  useEffect(() => {
    !!isFieldDisabled && !!canUpdateMeta && isEditable !== false && removeFieldMeta()
  }, [isFieldDisabled])

  useEffect(() => {
    if (!!isFormMounted && !isSectionReadOnly && _.isEmpty(meta))
      onValidateField(value, { dirty: false, initial: value, readonly: !!isFieldReadOnly, disabled: !!isFieldDisabled, include: alwaysInclude, focus: autoFocus, blur: false })
  }, [isFormMounted, _.isEmpty(meta), isSectionReadOnly])

  useEffect(() => {
    !!canUpdateMeta && onValidateField(value)
  }, [validateField])

  useEffect(() => {
    !!canUpdateMeta && !_.get(meta, 'active') && onValidateField(value)
  }, [_.flatten([value]).join(',')])

  useEffect(() => {
    !!canUpdateMeta && !!_.get(meta, 'readonly') !== !!isFieldReadOnly && updateFieldMeta({ readonly: !!isFieldReadOnly })
  }, [isFieldReadOnly])

  return [onFocus, onFieldBlur, onError]
}

export default useFieldReporter
