import { useRoleAuthorizations } from "../../../provider/hooks"

const usePageAuthorization = (pathname, use_local_context) => {
  const authorizations = useRoleAuthorizations(use_local_context)
  const matching_pages = _.filter(authorizations, (page) => !_.isEmpty(_.trimEnd(pathname, '/').match(new RegExp(`^${_.trimEnd(page.matcher, '/')}$`))))
  const sorted_pages = _.orderBy(matching_pages, (page) => page.matcher?.length, ['desc'])
  const authorization = _.head(sorted_pages)
  return {
    ...authorization,
    display: _.endsWith(pathname, '/create') ? !!authorization?.create || false : !!authorization
  }
}

export default usePageAuthorization