import { usePathname } from "../../../provider/hooks"
import usePageAuthorization from "./usePageAuthorization"


const useCurrentPage = (use_local_context) => {
  const current_path = usePathname()
  return usePageAuthorization(current_path, use_local_context)
}

export default useCurrentPage
