import { useTimer } from "../../hooks"

const TimerComponent = ({ datetime, isDisabled, mutator = _.identity }) => {
  const seconds = useTimer(datetime, { isDisabled })
  return _.isFinite(seconds) ? mutator(seconds) : null
}


export default TimerComponent

export {
  TimerComponent
}