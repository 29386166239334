import { useTimerSince } from '../../hooks'

const TimerSinceComponent = ({ startTime, mutator = _.identity, isDisabled, hideIfElapsed }) => {
  const seconds = useTimerSince(startTime, { isDisabled })
  if (!startTime || (hideIfElapsed && seconds <= 0)) return null
  return _.isFinite(seconds) ? mutator(seconds) : null
}


export default TimerSinceComponent
export {
  TimerSinceComponent
}