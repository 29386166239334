
import { useCallback } from 'react'
import _ from 'lodash'
import useModel from '../../store/useModel'
import useModelSearch from '../../store/useModelSearch'

const useOrderCancelReason = (order_id) => {
  const [order] = useModel('order', [order_id], { single: true, populate: ['assigned_transport'] })
  const current_status = order?.assigned_transport?.status || null

  const [reasons, , reasons_state] = useModelSearch('reason', 'get', {
    initial_filter: {},
    forced_filter: { current_status },
    mode: 'active',
    watchers: [current_status],
    validate_filter: useCallback(() => !_.includes(['complete', 'canceled'], current_status), [current_status]),
  })

  return [reasons, reasons_state]
}

export default useOrderCancelReason
